// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .success-container h1 {
    font-size: 2.5rem;
    color: #4caf50;
    margin-bottom: 1rem;
  }
  
  .success-container p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 2rem;
  }
  
  .home-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color: #45a049;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Success.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".success-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    text-align: center;\n    background-color: #f9f9f9;\n    font-family: Arial, sans-serif;\n  }\n  \n  .success-container h1 {\n    font-size: 2.5rem;\n    color: #4caf50;\n    margin-bottom: 1rem;\n  }\n  \n  .success-container p {\n    font-size: 1.2rem;\n    color: #555;\n    margin-bottom: 2rem;\n  }\n  \n  .home-button {\n    background-color: #4caf50;\n    color: white;\n    border: none;\n    padding: 0.8rem 2rem;\n    font-size: 1rem;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .home-button:hover {\n    background-color: #45a049;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
