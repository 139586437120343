import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Inventory from './pages/Inventory';
import Sales from './pages/Sales';
import Home from "./pages/Home"; // Asegúrate de que Home esté bien importado
import Login from "./pages/Login";
import Checkout from "./pages/Checkout";
import Success from "./pages/Success";
import PrivateRoute from "./PrivateRoute";

function App() {
  return (
    <Router>
      <Routes>
        {/* Ruta pública para login */}
        <Route path="/login" element={<Login />} />

        {/* Rutas privadas protegidas */}
        <Route 
          path="/home" 
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/inventory" 
          element={
            <PrivateRoute>
              <Inventory />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/sales" 
          element={
            <PrivateRoute>
              <Sales />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/checkout" 
          element={
            <PrivateRoute>
              <Checkout />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/success" 
          element={
            <PrivateRoute>
              <Success />
            </PrivateRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;
