import React from "react";
import axios from "../axiosConfig";  // Asegúrate de que la ruta a axiosConfig sea correcta
import { useLocation } from "react-router-dom";  // Importa useLocation desde react-router-dom

const Checkout = () => {
  const location = useLocation();  // Utiliza el hook useLocation para acceder al objeto location
  const { cartItems, total } = location.state || {};  // Asegúrate de que location.state esté definido

  // Verifica que cartItems y total existan
  if (!cartItems || !total) {
    return <div>Loading...</div>;  // Muestra un mensaje de carga si los datos no están disponibles
  }

  const finalizeSale = () => {
    if (cartItems.length === 0) {
      alert("Your cart is empty. Add products before finalizing the sale.");
      return;
    }

    const saleData = {
      items: cartItems.map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
      total: total,
    };

    // Usamos Axios para enviar los datos al backend
    axios
      .post('sales/', saleData)  // Usa el endpoint relativo, ya que axios ya tiene la URL base configurada
      .then((response) => {
        console.log("Server response:", response.data);  // Muestra la respuesta del servidor
        alert(`Sale ID: ${response.data.sale_id} completed successfully!`);
        
        // Limpiar el carrito
        localStorage.removeItem("cart");

        // Redirigir a la página de éxito
        window.location.href = "/success";  // Redirige a una página de éxito
      })
      .catch((error) => {
        console.error("Error completing the sale:", error);
        if (error.response && error.response.data) {
          alert(`Error: ${error.response.data.error || error.response.data.detail}`);
        } else {
          alert("There was an error completing the sale. Please try again.");
        }
      });
  };

  return (
    <div className="checkout-container">
      <h1>Checkout</h1>
      <div className="cart-summary">
        <h2>Items in Cart</h2>
        <ul>
          {cartItems.map((item) => (
            <li key={item.id}>
              <div className="cart-item">
                <img
                  src={`https:app.gemeloscoffeepos.online${item.image}`}
                  alt={item.name}
                  className="cart-image"
                />
                <div>
                  <h3>{item.name}</h3>
                  <p>Quantity: {item.quantity}</p>
                  <p>Total Price: C${item.price * item.quantity}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <h3>Total: C${total}</h3>
      </div>
      <button className="finalize-sale-button" onClick={finalizeSale}>
        Finalize Sale
      </button>
    </div>
  );
};

export default Checkout;
