import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    axios
      .post("https://app.gemeloscoffeepos.online/api/login/", { username, password })
      .then((response) => {
        // Si la respuesta es exitosa, almacenar el token
        localStorage.setItem("authToken", response.data.token); // Guarda el token
        localStorage.setItem("authToken", response.data.access);
        navigate("/home"); // Redirigir a la página de inicio, no a "/"
      })
      .catch((error) => {
        console.error("Login error:", error);
        alert("Invalid credentials");
      });
  };
  
  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
