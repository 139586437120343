import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // Si no hay token, redirige al login
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;

