import axios from 'axios';

//Crea una instancia de axios para la config global
const axiosInstance = axios.create({
    baseURL: 'https://app.gemeloscoffeepos.online/api/', //URL base de la API
});

//Interceptar todas las solicituddes para agregar token en las cabeceras
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken'); //obtener el token de localStorage
        console.log("Token en localStorage:", token); //depurar
        if (token) {
            config.headers.Authorization = `Bearer ${token}`; //agregar el token al encabezado
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;